var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-alert',{staticClass:"alert alert-custom alert-white alert-shadow fade show gutter-b",attrs:{"show":"","variant":"light"}},[_c('div',{staticClass:"alert-icon"},[_c('span',{staticClass:"svg-icon svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Tools/Compass.svg"}})],1)]),_c('div',{staticClass:"alert-text"},[_vm._v(" Berikut adalah "),_c('b',[_vm._v("Daftar Langganan")]),_vm._v(" yang ada di "+_vm._s(_vm.clinic_name)+" ")]),_c('b-button',{attrs:{"squared":"","variant":"success"},on:{"click":function($event){return _vm.$router.push('/subscribe/package')}}},[_vm._v("Berlangganan")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('Card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'actions' ? '20%' : '' })})})}},{key:"cell(payment_status)",fn:function(data){return [(
                    data.item.workstate_name == 'Paid' &&
                    data.item.is_expired == 0
                  )?_c('b-badge',{attrs:{"pill":"","variant":"success"}},[_vm._v("Lunas")]):_vm._e(),(
                    data.item.workstate_name == 'Unpaid' &&
                    data.item.is_expired == 0
                  )?_c('b-badge',{attrs:{"pill":"","variant":"warning"}},[_vm._v("Belum Lunas")]):_vm._e(),(data.item.is_expired == 1)?_c('b-badge',{attrs:{"pill":"","variant":"danger"}},[_vm._v("Kadaluarsa")]):_vm._e()]}},{key:"cell(display_total)",fn:function(data){return [_vm._v(" "+_vm._s(parseInt(data.item.display_total).toLocaleString("id-ID"))+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.$router.push({ path: '/billings/detail/' + data.item.id })}}},[_c('i',{staticClass:"fas fa-eye px-0"})]),(
                    data.item.workstate_name == 'Unpaid' &&
                    data.item.is_expired == 0
                  )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-warning",attrs:{"size":"sm","title":"Bayar"},on:{"click":function($event){return _vm.setPaymentData(data.item)}}},[_c('i',{staticClass:"fas fa-receipt px-0"})]):_vm._e(),(data.item.workstate_name == 'Paid')?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-success",attrs:{"size":"sm","title":"Invoice"},on:{"click":function($event){return _vm.$router.push({ path: '/billings/invoice/' + data.item.id })}}},[_c('i',{staticClass:"fas fa-receipt px-0"})]):_vm._e(),(data.item.is_expired == 1 )?_c('b-button',{staticClass:"btn-danger",attrs:{"size":"sm"},on:{"click":function($event){return _vm.deleteBilling(data.item.id)}}},[_vm._v(" Hapus ")]):_vm._e()]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"page-click":_vm.pageOnClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()]},proxy:true}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }