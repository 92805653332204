<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Berikut adalah <b>Daftar Langganan</b> yang ada di {{ clinic_name }}
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/subscribe/package')"
        >Berlangganan</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- <div class="d-flex align-items-center justify-content-end mt-n3">
              <div class="col-md-3">
                <treeselect
                  v-model="filter.blood_type_id"
                  :multiple="false"
                  :options="blood_type"
                  placeholder="Cari Berdasar Gol. Darah"
                  @select="filterByBloodType"
                />
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Pendonor"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div> -->
            <b-table striped hover :items="items" :fields="fields">
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>
              <template #cell(payment_status)="data">
                <b-badge
                  pill
                  variant="success"
                  v-if="
                    data.item.workstate_name == 'Paid' &&
                    data.item.is_expired == 0
                  "
                  >Lunas</b-badge
                >
                <b-badge
                  pill
                  variant="warning"
                  v-if="
                    data.item.workstate_name == 'Unpaid' &&
                    data.item.is_expired == 0
                  "
                  >Belum Lunas</b-badge
                >
                <b-badge pill variant="danger" v-if="data.item.is_expired == 1"
                  >Kadaluarsa</b-badge
                >
              </template>
              <template #cell(display_total)="data">
                {{ parseInt(data.item.display_total).toLocaleString("id-ID") }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({ path: '/billings/detail/' + data.item.id })
                  "
                >
                  <i class="fas fa-eye px-0"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-warning"
                  v-b-tooltip.hover
                  title="Bayar"
                  v-if="
                    data.item.workstate_name == 'Unpaid' &&
                    data.item.is_expired == 0
                  "
                  @click="setPaymentData(data.item)"
                >
                  <i class="fas fa-receipt px-0"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Invoice"
                  v-if="data.item.workstate_name == 'Paid'"
                  @click="
                    $router.push({ path: '/billings/invoice/' + data.item.id })
                  "
                >
                  <i class="fas fa-receipt px-0"></i>
                </b-button>
                <!-- <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="
                    $router.push({ path: '/donors/edit/' + data.item.id })
                  "
                  v-if="btn"
                >
                  <i class="fas fa-edit px-0"></i>
                </b-button> -->
                <b-button size="sm" class="btn-danger" v-if="data.item.is_expired == 1 " @click="deleteBilling(data.item.id)"> Hapus </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import saasModule from "@/core/modules/CrudSaasModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        start_date: "",
        end_date: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      fields: [
        {
          key: "number",
          labebl: "#",
          sortable: true,
        },
        {
          key: "display_invoice_id",
          label: "No. Invoice",
          sortable: true,
        },
        {
          key: "tenant_name",
          label: "Nama Klinik",
          sortable: true,
        },
        {
          key: "subscription_package_name",
          label: "Paket",
          sortable: true,
        },
        {
          key: "subscription_package_duration",
          label: "Jumlah Hari",
          sortable: true,
        },
        {
          key: "display_total",
          label: "Total Pembayaran",
          sortable: true,
        },
        {
          key: "payment_status",
          label: "Status Pembayaran",
          sortable: true,
        },

        { key: "actions", label: "Aksi" },
      ],
      items: [],
      blood_type: [],
      // User Access
      btn: false,
      clinic_name:
        window.configuration == null
          ? "Medhop"
          : window.configuration.clinic_name,
    };
  },

  methods: {
    pageOnClick(page) {
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let tenant = JSON.parse(localStorage.getItem("tenantconfig"));
      console.log(tenant);
      let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await saasModule.paginate(
        `billings/tenant/${tenant.id}`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    setPaymentData(val) {
      let duration = val.subscription_package_duration / 30;

      let data = {
        duration: duration,
        id: val.id,
        name: val.subscription_package_name,
        payment_method_category: val.payment_bank,
        payment_method_code: val.code,
        payment_method_name: val.payment_bank,
        price: val.subscription_package_price,
        tax_amount: val.tax_amount,
        total_amount: val.display_total,
      };

      console.log(data, "dataaaaa66677667766");
      localStorage.setItem("paymentDetailData", JSON.stringify(data));
      this.$router.push({ path: "/subscribe/payment/" });
    },

    async deleteBilling(id) {
      // Delete Data
      let result = await saasModule.delete("billings/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // async getBloodTypeOption() {
    //   let response = await module.setTreeSelect("blood-types");
    //   // Success
    //   if (response.state == "success") {
    //     // Set Option Select
    //     this.blood_type = response.data;
    //     this.blood_type.unshift({
    //       label: "Filter Berdasar Gol. Darah",
    //       id: "",
    //       isDisabled: true,
    //     });
    //   }
    // },

    // filterByName() {
    //   // this.pagination();
    //   this.generalFilter()
    // },

    // filterByBloodType(evt) {
    //   this.filter.blood_type_id = evt.id
    //   this.generalFilter()
    // },

    generalFilter() {
      this.pagination();
    },

    // setActiveButton(){
    //   let access_right_user = window.localStorage.getItem("access_right_display")
    //   let access_right = JSON.parse(access_right_user)

    //   let a
    //   for(a = 0; a < access_right.length; a++){
    //     if(access_right[a] == "2202"){
    //       this.btn = true
    //     }
    //   }
    // },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Langganan", route: "/billings" },
    ]);

    this.pagination();
    // this.getBloodTypeOption();
    // this.setActiveButton()
  },

  // watch: {
  //   'filter.blood_type_id': function (newVal, oldVal) {
  //     if (typeof newVal === 'undefined') {
  //       this.filter.blood_type_id = ''
  //       this.generalFilter()
  //     }
  //   }
  // },
};
</script>

<style>
</style>